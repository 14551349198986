@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.SingleDatePicker) {
    display: block;
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    top: 52px !important;
    width: 100%;
    min-height: auto;
    box-shadow: var(--boxShadowPopup);
    border-radius: 0 0 6px 6px;
    left: 0 !important;
    background-color: #fff;
    background: rgb(255, 255, 255) !important;
    border-radius: 16px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
    display: inline-block !important;
    /* padding: 24px 32px 16px !important; */
    position: absolute !important;
    /* top: -24px !important; */
    right: -32px !important;
    width: 100% !important;
    z-index: 1 !important;
    min-height: 350px !important;
    overflow: hidden;
    min-height: inherit;
 
  }

  & :global(.DateInput_input) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    display: block;
    width: 100%;

    padding: 5px 0 5px 30px;
    margin: 0;
    border-radius: 12px;
    border: 1px solid var(--marketplaceColor);
    background-color: #fff;
    outline: none;

    transition: all 0.1s ease-in-out;

    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%234A4A4A" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 8px 20px;
    min-height: 58px;
    &:focus { 
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      transition: all ease-in-out 100ms;
    }

    &::placeholder {
      color: var(--colorGrey300);
    }

    @media (--viewportMedium) {
      background-position: 8px 20px;
      font-size: 16px;
      line-height: 32px;
      padding: 12px 0 12px 35px;
      min-height: 58px;
      &:focus {
        border-radius: 6px;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        transition: all ease-in-out 100ms;
      }
    }
  }

  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
    width: 100% !important;
    > div {
      > div{
        width: 100%!important;
      }
    }
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    /* color: var(--colorWhite); */
    color: #000000;
    border: 0;
    svg{
      fill: #000;
      stroke: #000;
    }
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--colorWhite);
    top: 57px;
    width: 100%;
    text-align: center;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: var(--fontWeightRegular);
  }

  & :global(.CalendarMonth_caption) {
    font-weight: var(--fontWeightRegular);
    font-size: 21px;
    line-height: 24px;
    color: var(--colorWhite);

    margin: 1px 0 14px;

    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  td.CalendarDay__today span {
    color: black;
}


  & :global(.CalendarDay__default) {
    /* background-color: var(--marketplaceColor); */
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
    border-radius: 50%;
    color: rgb(106, 106, 106) !important;
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .DateInput_inputRoot__xZ8cW .CalendarMonth_caption strong {
    font-weight: 500;
}
  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
    color: black;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--colorSuccess);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: var(--DateInput_selectionHeight);
        background-color: #272abb;
        border-radius: calc(var(--DateInput_selectionHeight) / 2);
        color: #fff;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: rgb(255 255 255);
    border: 1px solid #dbdbdb;
    border-radius: 50%;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: rgb(176, 176, 176) !important;
    border: 0;
    /* text-decoration: line-through; */
    &:hover{
      background-color: #fff;
    }
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
    color: black;
    font-family: Sora;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }

  & :global(.DateInput__disabled) {
    background: none;
  }
  & :global(.DateInput_input__disabled) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;

    /* 6 + 24 + 4 + borders are divisible by 6 */
    padding: 6px 12px 4px 30px;
    background-color: #fff;

    /* Borders */
    border-radius: 6px;
    border: 1px solid #d8dce6;
    border-bottom-style: solid;
    border-bottom-color: var(--colorGrey100);
    outline: none;

    /* Transition */
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;

    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%23E6E6E6" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 8px 8px;

    /* Effects */
    &:hover {
      border: 1px solid #b8bfd1;
      transition: all ease-in-out 100ms;
    }
    &:focus {
      background-color: #fff;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      transition: all ease-in-out 100ms;
    }
    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
      /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
      padding: 7px 16px 7px 32px;
      line-height: 32px;
      background-position: 8px 13px;
    }
  }

  & :global(.DateInput_input__disabled::placeholder) {
    color: var(--colorGrey100);
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    width: 100%; 

    @media (--viewportMedium) {
      
      margin: 0 auto;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    /* top: 34px !important; */

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
       
      border-top: 0;
      min-height: auto;



      top: 85px !important;
    left: 50% !important;
    background: rgb(255, 255, 255) !important;
    border-radius: 16px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px !important;
    display: inline-block !important;
    position: absolute !important;
    right: -32px !important;
    width: 100% !important;
    z-index: 1 !important;
    min-height: 350px !important;
    overflow: hidden;
    max-width: 350px;
    transform: translate(-50%);
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  /* stroke: var(--colorWhite);
  fill: var(--colorWhite); */
  stroke: #000000;
  fill: #000000;
}
