@import '../../styles/customMediaQueries.css';

.fieldRoot {
  width: 100%;
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.mobileMargins {
  width: calc(100% - 48px);
  margin: 0 24px 6px 24px;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
  }
}
